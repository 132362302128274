import { useCurrentLocale } from '@/composables/useCurrentLocale'

export function useCategory({
  name,
  uid,
  image_on,
  image_off,
  illustration,
  children,
  parent,
  parent_uid,
  slug,
  slugs,
  mkp_products_count,
  count,
  stock_available,
  first_level_parent_uid,
  breadcrumb_path,
} = {}) {
  const category = {
    name,
    slug,
    uid,
    imageOn: image_on,
    imageOff: image_off,
    illustration,
    children,
    parent,
    parent_uid,
    first_level_parent_uid,
    breadcrumb_path,
    productsCount: mkp_products_count !== undefined ? mkp_products_count : count,
    stockAvailable: stock_available,
    isActive: false,
    slugs,
  }
  if (children !== undefined && children !== null) {
    category.children = children?.map(child => useCategory(child))
  } else {
    category.children = []
  }

  const hasChildren = () => {
    return category.children !== null && category.children?.length > 0
  }

  const thumbnail = () => {
    if (category.imageOn) {
      return category.imageOn?.replace('upload/', 'upload/c_fill,w_60,h_60,c_thumb,f_auto/')
    }
    return null
  }

  const stockAvailableByCountry = () => {
    const { country } = useCurrentLocale()
    return category.stockAvailable?.[country?.code]
  }

  return {
    ...category,
    hasChildren: hasChildren(),
    thumbnail: thumbnail(),
    stockAvailableByCountry: stockAvailableByCountry(),
  }
}
